<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar title="开卡记录" left-arrow></nav-bar>
    </template>
    <div class="recommend-header ds-flex">
      <div class="recommend-title">开卡会员</div>
      <div class="recommend-rightside ds-flex">
        <i class="iconfont2 icon--rili calc-icon"></i>
        <span>来源</span>
      </div>
    </div>
    <scroll-view
      height="fullView"
      ref="scroll"
      pullup
      @scrollToEnd="loadMore()"
    >
      <div class="customer-box ds-flex">
        <div
          class="van-hairline--bottom customer__item"
          :key="index"
          v-for="(item, index) in memberList"
        >
          <div class="customer__info info-box">
            <div class="info__avatar">
              <avatar
                shape="square"
                :avatarImage="imgFullFix(item.headImg, 'small')"
                :avatarName="item.username"
              />
            </div>
            <div class="info__other">
              <div class="info__name text-elps">
                {{ item.username
                }}<i
                  :class="[
                    index > 3 ? 'icon-xingbienan' : 'icon-xingbienv',
                    'iconfont2',
                  ]"
                ></i>
              </div>
              <div class="info__remark text-elps">{{ item.phone }}</div>
            </div>
          </div>
          <div class="people__sale-money">
            <div class="">
              <div class="flex-algin-center">
                {{
                  item.recommendName ? item.recommendName + "推荐" : "自助领卡"
                }}
              </div>
              <div class="fs-12">
                {{ dayjs(item.activateTime).format("YYYY-MM-DD") }}
              </div>
            </div>
            <!-- <i class="van-icon van-icon-arrow-up more-icon"></i> -->
          </div>
        </div>
        <scroll-view-loading :isFinished="isFinished" />
        <div
          v-if="empty"
          class="ds-flex align-center"
          style="
            justify-content: center;
            width: 100%;
            mini-height: 100px;
            color: #999;
          "
        >
          暂无数据
        </div>
      </div>
    </scroll-view>
  </page-view>
</template>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<script>
import Avatar from '@/components/avatar'
import PageView from '@/layouts/PageView'
import ScrollView from '@/components/scroll-view'
import ScrollViewLoading from '@/components/scroll-view-loading'
import NavBar from '@/components/nav-bar'
import { imgFullFix } from '@/utils/img'
import WechatCardModel from '@/model/wechat-card/index'
import dayjs from 'dayjs'
import { Divider, Loading, Image } from 'vant'
export default {
  data () {
    return {
      empty: false,
      isFinished: false,
      loading: false, // 加载中
      loaded: false, // 加载完成
      empty: false,
      loadMoreModel: false,
      noMore: false,
      pageConfig: {
        pageIndex: 1,
        pageSize: 15,
        totalCount: 0
      },
      searchObj: {
        // benginDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
        benginDate: '2020-01-01',
        endDate: dayjs().format('YYYY-MM-DD')
      },
      memberList: [],
      dashbordData: {
        recommandMemberCount: 0,
        newWeChatCardCount: 0,
        saleAmount: 0
      }
    }
  },
  components: {
    Avatar,
    [Divider.name]: Divider,
    [Loading.name]: Loading,
    [Image.name]: Image,
    PageView,
    ScrollView,
    NavBar,
    ScrollViewLoading,
    [Loading.name]: Loading,
  },
  created () {
  },
  mounted () {
    this.loadMembers()
    // this.initDashbord()
    // this.loadMemberList(true)
  },
  beforeDestroy () {
  },
  methods: {
    dayjs,
    imgFullFix,
    // 加载更多数据
    async loadMore () {
      if (this.isFinished || this.loading) return
      this.loading = true
      await this.loadMembers()
      this.loading = false
    },

    async loadMembers (isNewPage = false) {
      if (isNewPage) {
        this.memberList = []
        this.pageConfig.pageIndex = 0
        this.pageConfig.totalCount = 0
        this.isFinished = false
        this.empty = false
      }
      const { code, data } = await WechatCardModel.getActivatePageList(this.pageConfig.pageIndex, this.pageConfig.pageSize)
      if (code === 200) {
        this.memberList = this.memberList.concat(data.items)
        this.pageConfig.pageIndex += 1
        this.pageConfig.totalCount = data.totalCounts
        this.isFinished = data.items.length < this.pageConfig.pageSize
        this.empty = this.pageConfig.pageIndex === 0 && !data.items.length
      } else {
        throw Error('加载推荐用户列表失败')
      }
      // const start = this.pageConfig.pageIndex * this.pageConfig.pageSize
      // const limit = this.pageConfig.pageSize
      // const { result, data } = await WechatCardModel.getRecommandMembersByPage(
      //   start,
      //   limit
      // )
      // if (result) {
      //   this.memberList = this.memberList.concat(data.items)
      //   this.pageConfig.pageIndex += 1
      //   this.pageConfig.totalCount = data.totalCount
      //   this.isFinished = data.items.length < this.pageConfig.pageSize
      //   this.empty = this.pageConfig.pageIndex === 0 && !data.items.length
      // } else {
      //   throw Error('加载推荐用户列表失败')
      // }
      // 数据加载完成之后要进行重新计算当前宽度
      this.$nextTick(() => {
        this.$refs.scroll.refresh()
      })
    },
    jumpToDetail (value) {
      this.$router.push({
        path: '/recommend/detail',
        query: {
          id: value.memberId,
          name: value.memberName,
          portrait: value.portrait,
          recommandSaleAmount: value.recommandSaleAmount,
          memberNo: value.memberNo,
          recommandCount: value.recommandCount
        }
      })
    }
  },
  computed: {
  },
  watch: {}
}
</script>
