import { apiUrl, wechatUrl, javaApi } from '@/config/net'
import api from '@/tools/request'
import dayjs from 'dayjs'
// const javaApi ='api'
/**
 * 微信会员卡通信模型
 */
export default class WecharCardModel {
  /**
   * 获取当前微信会员卡总消费
   */
  static getTotalSaleMoney () {
    return api.get(`${wechatUrl}/account/sales-money`).then(json => json)
  }
  static getPosterQr (accountId) {
    return api.get(`${javaApi}/miniapp/m/wechatCard/openCardUrl?accountId=${accountId}&type=1`).then(json => json)
  }
  /**
   * 获取微信会员卡总人数
   */
  static getTotalMemberNum () {
    return api.get(`${wechatUrl}/account/wechatcard-members-count`).then(json => json)
  }

  /**
   * 分页获取微信会员卡推荐会员
   * @param {Number} start
   * @param {Number} limit
   */
  static getRecommandMembersByPage (start = 0, limit = 10) {
    return api.get(`${apiUrl}/account/wechatcard-recommand-members?start=${start}&limit=${limit}`)
      .then(json => json)
  }
  static getCardDetail () {
    return api.get(`${javaApi}/miniapp/wechatCard/detail`)
      .then(json => json)
  }
  /**
   * 获取最近七天的消费记录
   */
  static getLast7DaysList () {
    return api.get(`${apiUrl}/account/wechatcard-members-sales`).then(json => json)
  }

  /**
   * 获取最近七天的会员卡趋势
   */
  static getLast7DayCount () {
    const params = {
      start: dayjs().subtract(6, 'days').format('YYYY-MM-DD'),
      end: dayjs().format('YYYY-MM-DD'),
      type: 1
    }
    return api.get(`${javaApi}/miniapp/wechatCard/cardChart`, { params }).then(json => json.data)
  }
  /**
   * 获取最近两天的新增会员数
   */
  static getLasy2DaysMemberList () {
    const endDate = dayjs().format('YYYY-MM-DD')
    const startDate = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    return api.get(`${wechatUrl}/wechat-cards/members-count?StartDate=${startDate}&EndDate=${endDate}&SearchType=1`).then(json => json)
  }

  static getRecommandMemberList (beginDate, endDate, currentPage, size) {
    const params = {
      currentPage,
      size,
      beginDate,
      endDate,
      type: 5
    }
    return api.get(`${javaApi}/miniapp/wechatCard/recommendRank`, { params }).then(json => json)

    // return api.post(`${apiUrl}/account/wechatcard-members-recommand-rank`, params).then(json => json)
  }

  static getReDetail (openid, benginDate, endDate, currentPage, size) {
    const params = {
      openid,
      currentPage,
      size,
      benginDate,
      endDate,
      type: 4
    }
    return api.get(`${javaApi}/miniapp/wechatCard/recommendedList`, params).then(data => data)
  }
  static getActivatePageList (currentPage, size = 15) {
    const params = {
      currentPage,
      size
    }
    return api.get(`${javaApi}/miniapp/wechatCard/activatePageList`, {params}).then(json => json)
  }
  /**
   * 会员卡推荐排名dashboard
   * @param {String} benginDate 2020-01-01
   * @param {*} endDate 2020-02-15
   */
  static getRecommandMemberDashboard (benginDate, endDate) {
    const params = {
      DateModel: {
        BeginDate: benginDate,
        EndDate: endDate
      }
    }
    return api.post(`${apiUrl}/account/wechatcard-members-recommand-rank-summary`, params).then(json => json)
  }
}
